import { WorkLayout } from "components/main-layout/work-layout.component";
import { Box, Divider, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import Iframe from "react-iframe";
import './index.css';

const API = "AIzaSyCTiDAPZqpnK1el0V1UI7Ugmsgf-fMI26k";
const playlistId = "PLE-6o_HrqAnMl4q0nDjJ_WHd_J-NGPzNo";
const fetchURL = `https://www.googleapis.com/youtube/v3/playlistItems?key=${API}&playlistId=${playlistId}&part=snippet&maxResults=100`;

export const Work: React.FC = () => {
    const [playlistData, setPlaylistData] = useState<any[]>([]);
    useEffect(() => {
      fetch(fetchURL).then((response) => response.json()).then((resJson) => {
        const results = resJson.items;
        setPlaylistData(results);
      });
    }, []);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    return (
      <WorkLayout>
        <Box py={isSmall ? 4 : 8} px={isSmall ? 4 : 28}>
          <Grid container spacing={3}>
            {playlistData.map((item, index) => {
              return (
                <Grid container item direction="row" key={index} py={12}>
                  <Grid container item xs={isSmall ? 12 : 7} direction={isSmall ? "row" : "column"}>
                    <Iframe url={`https://www.youtube.com/embed/${item.snippet.resourceId.videoId}`}
                      width={isSmall ? "464px" : "752px"}
                      height={isSmall ? "261px" : "423px"}
                      id=""
                      className=""
                      display="block"
                      frameBorder={0}
                      position="relative"
                    />
                  </Grid>
                  <Grid container item xs={isSmall ? 12 : 5} direction={isSmall ? "row" : "column"}>
                    <Typography variant="h3" sx={{ paddingBottom: '16px', paddingTop: isSmall ? '16px' : '0px', fontWeight: '600', color: '#242325' }}>{item.snippet.title}</Typography>
                    {isSmall ? '' : <Divider />}
                    <Typography variant="body1" sx={{ paddingTop: '16px', color: '#242325', fontSize: '18px' }}>{item.snippet.description ? item.snippet.description : "No Description Provided"}</Typography>
                  </Grid>
                  {isSmall ? <Divider sx={{ width: '85vw', color: '#242325', borderWidth: '1px', borderColor: '#242325', marginTop: '16px' }}/> : ''}
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </WorkLayout>
    );
};
