import { MainLayout, SectionContainer } from "components";
import {
  AboutSection,
  ContactSection,
  IntroSection,
  SkillsSection,
  WorkSection,
} from "sections";
import { SectionIdEnum } from "types";

const sections = [
    {
        sectionId: SectionIdEnum.intro,
        component: <IntroSection />,
    },
    {
      sectionId: SectionIdEnum.work,
        component: <WorkSection />,
    },
    {
      sectionId: SectionIdEnum.about,
      component: <AboutSection />,
    },
    {
        sectionId: SectionIdEnum.contact,
        component: <ContactSection />,
    },
];

export const Main: React.FC = () => {
    return (
      <MainLayout>
        {sections.map(({ component, sectionId }) => {
            return (
              <SectionContainer sectionId={sectionId} key={sectionId}>
                {component}
              </SectionContainer>
            );
        })}
      </MainLayout>
    );
};
