import { Box, Container } from '@mui/material';
import { SectionIdEnum } from 'types';

export type SectionContainerProps = {
    children: React.ReactNode;
    sectionId: SectionIdEnum;
};

export const SectionContainer: React.FC<SectionContainerProps> = ({ sectionId, children }) => {
    return (
      <div id={sectionId} className={sectionId} key={sectionId}>
        <Container>
          <Box minHeight="500px">{children}</Box>
        </Container>
      </div>
    );
};
