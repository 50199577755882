import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import './about.css';
import { Testimonials } from "./testimonials";
import AOS from 'aos';
import "aos/dist/aos.css";
import { useEffect } from "react";

export const AboutSection: React.FC = (data) => {
    useEffect(() => {
      AOS.init({ duration: 1000 });
    }, []);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
      <Box py={4} px={isSmall ? 4 : 0} sx={{ paddingTop: '96px' }}>
        <Grid container spacing={3}>
          <Grid container direction="row" spacing={24}>
            <Grid container item xs={isSmall ? 12 : 6} direction="column">
              <Typography data-aos="fade-up" variant="h2" sx={{ paddingBottom: '32px', fontWeight: '600', color: '#242325' }}>About Me</Typography>
              <Typography data-aos="fade-up" variant="body1" sx={{ paddingBottom: isSmall ? '32px' : '96px', color: '#242325', fontSize: '18px' }}>I am a skilled and experienced content creator with a passion for storytelling and visual media. With a strong background in video editing and production, I am adept at using Adobe Premiere Pro and After Effects to create engaging and high-quality videos. I am a quick learner and a creative thinker, and I am always eager to take on new challenges and opportunities.<br/> <br/>If you are looking for a skilled and experienced content creator, please feel free to connect with me. I would be happy to discuss how I can contribute to your team and help you achieve your goals.</Typography>
              <Button data-aos="fade-up" variant="contained" href="https://www.linkedin.com/in/devinwelch/" target="_blank" disableElevation sx={{ width: "25%", paddingTop: '10px', paddingBottom: '10px', fontWeight: '600' }}>
                Connect
              </Button>
            </Grid>
            {isSmall ? '' :
              <Grid container item xs={6} direction="column">
                <div className="DataBoxContainer">
                  <Box
                    sx={{
                      width: '400px',
                      height: '600px',
                      backgroundColor: "#226ec1",
                      borderRadius: '20px',
                      position: 'absolute',
                      marginLeft: '96px'
                    }}
                  >
                  </Box>
                  <Box
                    data-aos="flip-right"
                    sx={{
                      width: '400px',
                      height: '600px',
                      borderRadius: '10px',
                      position: 'relative',
                      bottom: '50px'
                    }}
                  >
                    <img src={require('../../assets/AboutMe.jpg')} width="400px" height="600px" />
                  </Box>
                </div>
              </Grid>
            }
            <Grid container item xs={6} direction="row">
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
};
