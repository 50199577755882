import {
  AppBar, Box, Toolbar, Typography, useMediaQuery, useTheme,
} from "@mui/material";
import { logoImg } from "assets";
import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { SectionIdEnum } from "types";
import { Navigation } from "./navigation/navigation.component";

export type MainLayoutProps = {
    children: React.ReactNode;
};

export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
      <Box height="100vh">
        <AppBar elevation={0} position='fixed' sx={{ background: '#226ec1', color: '#E6E6E6', boxShadow: 'none' }}>
          <Toolbar>
            <Box flexGrow={1}>
              <AnchorLink href={`#${SectionIdEnum.intro}`} offset={isSmall ? '56px' : '64px'} className="all_unset">
                <Box display="flex" alignItems="center" gap={0.5}>
                  <Typography variant="h5" sx={{ width: 'fit-content', fontWeight: '600', cursor: 'pointer' }}>Devin Welch</Typography>
                </Box>
              </AnchorLink>
            </Box>
            <Navigation />
          </Toolbar>
        </AppBar>
        <Box>
          <Toolbar />
          {children}
        </Box>
      </Box>
    );
};
