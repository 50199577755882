import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import Typed from 'react-typed';

export const IntroSection: React.FC = () => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    return (
      <Box py={isSmall ? 6: 32}>
        <Grid container spacing={3}>
          {isSmall ? '' :
            <Grid container item xs={2} direction="column">
            </Grid>
          }
          <Grid container item xs={isSmall ? 12 : 10} alignItems={isSmall ? 'center' : 'start'} justifyContent={isSmall ? 'center' : 'start'} direction="column">
            <Typography variant={isSmall ? "h5" : "h2"} className="titleHeading" sx={{ fontWeight: '600', fontSize: isSmall ? '1.5rem' : '3.75rem' }}>Hello, my name is Devin Welch</Typography>
              <Typography variant={isSmall ? "h6" : "h3"} mb={1} fontWeight={500} fontSize={isSmall ? '1.5rem' : '3rem'} className="descriptionHeading">
              I'm a{' '}
              <Typed
                typeSpeed={150}
                backSpeed={100}
                backDelay={2000}
                style={{ fontWeight: '800' }}
                loop
                strings={[
                  "video editor",
                  "videographer",
                  "certified drone pilot"
                ]}
                className="typedText"
              />
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
};
