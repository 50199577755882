import { Close, Menu } from "@mui/icons-material";
import {
    AppBar,
    Box, Button, Dialog, Hidden, IconButton, Slide, Toolbar, Typography, useMediaQuery, useTheme,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { SectionIdEnum } from "types";

const navigationItems = [
    {
      text: 'Work',
      to: SectionIdEnum.work,
    },
    {
      text: 'About',
      to: SectionIdEnum.about,
    },
    {
        text: 'Contact',
        to: SectionIdEnum.contact,
    },
];

const Transition = React.forwardRef((
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) => {
    return <Slide direction='left' ref={ref} {...props} />;
});

export const Navigation: React.FC = () => {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const onOpenHandler = () => {
        setOpen(true);
    };

    const onCloseHandler = () => {
        setOpen(false);
    };

    const mappedItems = (
      navigationItems.map(({ to, text }) => {
        return (
          <AnchorLink key={to} href={`#${to}`} offset={isSmall ? '56px' : '64px'} className="all_unset">
            <Button color="inherit" size="large" fullWidth={isSmall} onClick={onCloseHandler} sx={{ paddingTop: '16px', paddingBottom: '16px', fontWeight: '600', color: isSmall ? '#242325' : '#ffffff' }}>
              {text}
            </Button>
          </AnchorLink>
        );
      })
    );
    Transition.displayName = 'NavbarTransition';

    return (
      <>
        <Hidden smDown>
          <Box display="flex" gap={2}>
            {mappedItems}
          </Box>
        </Hidden>
        <Hidden smUp>
          <IconButton color="inherit" onClick={onOpenHandler}>
            <Menu />
          </IconButton>
          <Dialog
            open={open}
            fullScreen
            fullWidth
            TransitionComponent={Transition}
            hideBackdrop
            PaperProps = {{
                sx: {
                    boxShadow: 'none',
                },
            }}
          >
            <AppBar elevation={0} position="static" sx={{ background: '#226ec1', color: 'text.primary', boxShadow: 'none' }}>
              <Toolbar>
                <Typography variant="h5" sx={{ flexGrow: 1 }}>
                </Typography>
                <IconButton sx={{ color: '#E6E6E6' }} onClick={onCloseHandler}>
                  <Close />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Box display="flex" flexDirection="column" py={3} width="100%" sx={{ }}>
              {mappedItems}
            </Box>
          </Dialog>
        </Hidden>
      </>
    );
};
